import { useEffect, useState } from "react";

import Editor from "./Editor";

import "./App.css";

function getFileFromPasteEvent(event) {
  const items = (event.clipboardData || event.originalEvent.clipboardData)
    .items;

  for (var i = 0; i < items.length; i++) {
    if (items[i].kind === "file" || items[i].type.indexOf("image") === 0) {
      return items[i].getAsFile();
    }
  }
}

function App() {
  const [file, setFile] = useState(null);

  useEffect(() => {
    const handleDrop = (e) => {
      e.preventDefault();
      var dt = e.dataTransfer;
      var files = dt.files;

      setFile(files[0]);
    };

    const handleDragOver = (e) => {
      e.preventDefault();
    };

    const handleFilePaste = (event) => {
      const file = getFileFromPasteEvent(event);
      if (!file) {
        return;
      }

      setFile(file);
    };

    document.addEventListener("drop", handleDrop);
    document.addEventListener("dragover", handleDragOver, false);
    document.addEventListener("paste", handleFilePaste);

    return () => {
      document.removeEventListener("drop", handleDrop);
      document.removeEventListener("dragover", handleDragOver);
      document.removeEventListener("paste", handleFilePaste);
    };
  }, []);

  return <Editor file={file} />;
}

export default App;
