import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import blur from "./utils/blur";
import crop from "./utils/crop";
import {
  createRectangle,
  drawRectangle,
  createBlurredImageElement,
} from "./utils/shapes";
import loadImage from "./utils/loadImage";
import { downloadBase64AsFile } from "./utils/download";
import setupHighDPICanvas from "./utils/setupHighDPICanvas";
import { Slider } from "@mui/material";
import { Button } from "@mui/material";
import "./App.css";
import { debounce } from "lodash";

function Editor({ file }) {
  const canvas = useRef(null);
  const canvasBg = useRef(null);
  const canvasPreview = useRef(null);
  const [elements, setElements] = useState([]);
  const [currentElement, setCurrentElement] = useState(null);
  const [drawing, setDrawing] = useState(false);
  const [isPictureAvailable, setIsPictureAvailable] = useState(false);
  const [blurStrength, setBlurStrength] = useState(10);
  // const debouncedBlurStrength = useDebounce(blurStrength, 500);

  const handleMouseDown = (e) => {
    if (!isPictureAvailable) {
      return;
    }
    setDrawing(true);
    const rect = canvasPreview.current.getBoundingClientRect();
    const scaleX = canvasPreview.current.width / rect.width;
    const scaleY = canvasPreview.current.height / rect.height;

    // checking if current element does not exists
    // ensures it does not reset the current element cordinates when
    // the mouse moves out of the canvas and comes back, and causes the
    // mouse down to register again

    if (!currentElement) {
      const element = createRectangle(
        (e.nativeEvent.clientX - rect.left) * scaleX,
        (e.nativeEvent.clientY - rect.top) * scaleY,
        (e.nativeEvent.clientX - rect.left) * scaleX,
        (e.nativeEvent.clientY - rect.top) * scaleY
      );
      setCurrentElement(element);
    }
  };

  const handleMouseMove = (e) => {
    if (!drawing) {
      return;
    }

    const rect = canvasPreview.current.getBoundingClientRect();
    const scaleX = canvasPreview.current.width / rect.width;
    const scaleY = canvasPreview.current.height / rect.height;

    const { clientX, clientY } = e;
    const { x, y } = currentElement;
    const updatedElement = createRectangle(
      x,
      y,
      (clientX - rect.left) * scaleX,
      (clientY - rect.top) * scaleY
    );
    setCurrentElement(updatedElement);
  };

  const handleMouseUp = (e) => {
    if (!isPictureAvailable || !drawing || !currentElement) {
      return;
    }
    setDrawing(false);
    const sourceWidth = currentElement.x2 - currentElement.x;
    const sourceHeight = currentElement.y2 - currentElement.y;
    const actualOffsetX =
      sourceWidth < 0 ? currentElement.x + sourceWidth : currentElement.x;
    const actualOffsetY =
      sourceHeight < 0 ? currentElement.y + sourceHeight : currentElement.y;
    const actualWidth = Math.abs(sourceWidth);
    const actualHeight = Math.abs(sourceHeight);

    if (actualWidth === 0 || actualHeight === 0) {
      return;
    }
    const img = crop(
      canvasBg.current,
      actualOffsetX,
      actualOffsetY,
      actualWidth,
      actualHeight
    );

    const selectedImage = createBlurredImageElement(
      actualOffsetX,
      actualOffsetY,
      blur(img, 0, 0, img.width, img.height, blurStrength),
      {
        img,
        width: img.width,
        height: img.height,
      }
    );
    setElements((prevState) => [...prevState, selectedImage]);
    setCurrentElement(null);
  };

  useEffect(() => {
    var imageType = /image.*/;

    if (file === null) {
      return;
    }

    // don't try to process non-images
    if (!file.type.match(imageType)) {
      return;
    }

    var reader = new FileReader();
    reader.onload = (function (
      canvasBackground,
      canvasWithDrawings,
      canvasPreview
    ) {
      return function (e) {
        canvasBackground
          .getContext("2d")
          .clearRect(0, 0, canvasBackground.width, canvasBackground.height);
        loadImage(e.target.result, canvasBackground, 0, 0, (data) => {
          canvasWithDrawings.width = data.width;
          canvasWithDrawings.height = data.height;
          canvasPreview.width = data.width;
          canvasPreview.height = data.height;
          canvasPreview.getContext("2d").drawImage(canvasBackground, 0, 0);
        });
        // loadImage(e.target.result, canvasPreview, 0, 0, (data) => {
        //   const idealWidth = Math.min(1000, data.width);
        //   const aspectRatio = data.height / data.width;

        //   canvasPreview.width = idealWidth;
        //   canvasPreview.height = idealWidth * aspectRatio;
        // });

        setIsPictureAvailable(true);
        setElements([]);
      };
    })(canvasBg.current, canvas.current, canvasPreview.current);
    reader.readAsDataURL(file);
  }, [file]);

  const handleShortcut = useCallback((e) => {
    // undo
    if (e.key === "z" && (e.ctrlKey || e.metaKey)) {
      setElements((prevState) => {
        if (prevState.length === 0) {
          return prevState;
        }
        return prevState.slice(0, -1);
      });
    }

    // copy to clipboard
    if (e.key === "c" && (e.ctrlKey || e.metaKey)) {
      canvasPreview.current.toBlob((blob) => {
        navigator.clipboard.write([
          new window.ClipboardItem({ "image/png": blob }),
        ]);
      }, "image/png");
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", handleShortcut);

    return () => {
      document.removeEventListener("keydown", handleShortcut);
    };
  }, [handleShortcut]);

  useEffect(() => {
    const drawingCanvas = canvas.current;
    const context = drawingCanvas.getContext("2d");
    const canvasPreviewContext = canvasPreview.current.getContext("2d");
    context.clearRect(0, 0, drawingCanvas.width, drawingCanvas.height);

    elements.forEach((element) => {
      switch (element.type) {
        case "rectangle":
          drawRectangle(context, element);
          break;
        case "blurred-image":
          context.putImageData(element.image, element.x, element.y);
          break;
        default:
          break;
      }
    });
    if (currentElement) {
      drawRectangle(context, currentElement);
    }
    canvasPreview.current
      .getContext("2d")
      .clearRect(
        0,
        0,
        canvasPreview.current.width,
        canvasPreview.current.height
      );
    canvasPreviewContext.drawImage(canvasBg.current, 0, 0);
    canvasPreviewContext.drawImage(canvas.current, 0, 0);
  }, [elements, currentElement]);

  useEffect(() => {
    setupHighDPICanvas(canvasBg.current);
    setupHighDPICanvas(canvas.current);
    setupHighDPICanvas(canvasPreview.current);
  }, []);

  const handleExport = () => {
    const finalImage = canvasPreview.current.toDataURL("image/png");

    downloadBase64AsFile(finalImage, `screenshot-${Date.now()}.png`);
  };

  const handleReset = () => {
    setElements([]);
    setBlurStrength(10);
  };

  const changeBlur = useMemo(() => {
    return debounce((value) => {
      setElements(
        elements.map((element) => {
          switch (element.type) {
            case "blurred-image":
              const blurredImage = blur(
                element.sourceImage.img,
                0,
                0,
                element.sourceImage.width,
                element.sourceImage.height,
                value
              );

              return createBlurredImageElement(
                element.x,
                element.y,
                blurredImage,
                element.sourceImage
              );

            default:
              return element;
          }
        })
      );
    }, 500);
  }, [elements]);

  const handleBlurStrengthChange = (e) => {
    setBlurStrength(parseInt(e.target.value));
    changeBlur(parseInt(e.target.value));
  };

  return (
    <div className="flex justify-center mt-16">
      <div>
        <div className="App  mb-4 mx-auto">
          {!isPictureAvailable && (
            <p className="w-full h-full flex items-center justify-center border-dashed border-2 border-sky-500 shadow-md">
              <span className="font-bold">Drop/paste image here</span>
            </p>
          )}
          <canvas
            ref={canvasBg}
            className="canvas hacky-center"
            id="canvas-bg"
            width="1000px"
            height="500px"
          ></canvas>
          <canvas
            ref={canvas}
            className="canvas hacky-center"
            id="canvas"
            width="1000px"
            height="500px"
          ></canvas>
          <canvas
            ref={canvasPreview}
            className="canvas-preview shadow-lg hacky-center"
            width="1000px"
            height="500px"
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
          ></canvas>
        </div>
        <div>
          <label>Blur Strength</label>
          <br />
          <Slider
            min={1}
            max={50}
            size="small"
            aria-label="Blur Strength"
            value={blurStrength}
            onChange={handleBlurStrengthChange}
            valueLabelDisplay="auto"
          />
        </div>
        <Button
          variant="contained"
          disabled={!isPictureAvailable}
          onClick={handleExport}
          sx={{ mr: 2 }}
        >
          Save
        </Button>
        <Button variant="outlined" onClick={handleReset}>
          Reset
        </Button>
      </div>
    </div>
  );
}

export default Editor;
