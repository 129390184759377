import getIdealDimensions from "./getIdealDimensions";

// load image and resize canvas
const loadImage = (image, canvas, x, y, callback) => {
  const img = new Image(); // Create new img element
  img.addEventListener(
    "load",
    () => {
      const { width, height } = getIdealDimensions(
        img.width,
        img.height,
        1000,
        500
      );
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, x, y, width, height);
      callback && callback({ width, height });
    },
    false
  );
  img.src = image; // Set source path
};

export default loadImage;
