export const createRectangle = (x, y, x2, y2) => {
  return {
    type: "rectangle",
    x,
    y,
    x2,
    y2,
  };
};

export const createImageElement = (x, y, image) => {
  return {
    type: "image",
    x,
    y,
    image,
  };
};

export const createBlurredImageElement = (x, y, image, sourceImage) => {
  return {
    type: "blurred-image",
    x,
    y,
    image,
    sourceImage,
  };
};


export const drawRectangle = (context, rect) => {
  context.fillStyle = 'rgba(0,0,0,0.5)';
  context.beginPath();
  context.fillRect(
    rect.x,
    rect.y,
    Math.abs(rect.x2 - rect.x),
    Math.abs(rect.y2 - rect.y)
  );
  context.stroke();
};
