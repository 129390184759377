const dpr = 2;

const getIdealDimensions = (srcWidth, srcHeight, maxWidth, maxHeight) => {
  var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

  return {
    width: srcWidth * ratio * dpr,
    height: srcHeight * ratio * dpr,
  };
};

export default getIdealDimensions;
