const crop = (canvas, offsetX, offsetY, width, height) => {
  const buffer = document.createElement("canvas");
  const b_ctx = buffer.getContext("2d");
  buffer.width = width;
  buffer.height = height;
  b_ctx.drawImage(
    canvas,
    offsetX,
    offsetY,
    width,
    height,
    0,
    0,
    buffer.width,
    buffer.height
  );
  return b_ctx.getImageData(0, 0, width, height);
};

export default crop;
